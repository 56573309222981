<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.subscribe_id" @change="Search" placeholder="订阅唯一编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.status" @change="Search" placeholder="状态">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="未订阅" :value="0"></el-option>
            <el-option label="订阅" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="付款周期">
        <template v-slot="scope">
          <span>{{ scope.row.payment_cycles }}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额">
        <template v-slot="scope">
          <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="币种">
        <template v-slot="scope">
          <span>{{ scope.row.currency }}</span>
        </template>
      </el-table-column>
      <el-table-column label="周期单位">
        <template v-slot="scope">
          <span>{{ scope.row.cycles_unit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="周期时长">
        <template v-slot="scope">
          <span>{{ scope.row.cycles_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订阅状态">
        <template v-slot="scope">
          <span>{{ scope.row.status==0?'未订阅':scope.row.status==1?'订阅':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="首次付款时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.initial_payment_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="剩余付款周期">
        <template v-slot="scope">
          <span>{{ scope.row.remain_cycles }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订阅创建时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订阅取消时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.cancel_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="OrderInfo(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog title="订阅详情" v-model="dialogFormVisible" width="1100px">
      <el-form :model="form" :inline="true" :rules="rules" ref="form">
        <el-form-item label="付款周期" prop="name" label-width="110px">
          <el-input v-model="form.payment_cycles" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="金额" label-width="110px">
          <el-input v-model="form.amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="币种" prop="name" label-width="110px">
          <el-input v-model="form.currency" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="周期单位" label-width="110px">
          <el-input v-model="form.cycles_unit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="周期时长" prop="name" label-width="110px">
          <el-input v-model="form.cycles_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="订阅状态" label-width="110px">
          <el-input v-model="form.status" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="首次付款时间" prop="name" label-width="110px">
          <el-input v-model="form.initial_payment_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="剩余付款周期" label-width="110px">
          <el-input v-model="form.remain_cycles" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="订阅创建时间" prop="name" label-width="110px">
          <el-input v-model="form.create_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="订阅取消时间" label-width="110px">
          <el-input v-model="form.cancel_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="提供的服务" prop="name" label-width="110px">
          <el-input v-model="form.service" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      title: '',
      search: {
        page: 1,
        limit: 10,
        subscribe_id: '',
        status: '',
      },
      list: [],
      edit: false,
      dialogFormVisible: false,
      form: {
        payment_cycles: '',
        amount: '',
        currency: '',
        cycles_unit: '',
        cycles_time: '',
        status: '',
        initial_payment_time: '',
        remain_cycles: '',
        create_time: '',
        cancel_time: '',
        service: '',
      },
      rules: {
        name: [{ required: true, message: '请输入页面名称', trigger: 'blur' }]
      },
      total: 0
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search(){
      this.api.OrderSubscribe(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    OrderInfo (item) {
      this.api.OrderSubscribeInfo({
        code: item.subscribe_id
      }).then(res => {
         if (res.data.code == 200) {
          this.dialogFormVisible = true
          this.form = res.data.data
          this.form.status = res.data.data.status == 0?'未订阅':res.data.data.status == 1?'订阅':'未知'
          this.form.initial_payment_time = this.ChangeTime(res.data.data.initial_payment_time)
          this.form.create_time = this.ChangeTime(res.data.data.create_time)
          this.form.cancel_time = this.ChangeTime(res.data.data.cancel_time)
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
